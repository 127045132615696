import React from "react"
import { Button } from "react-bootstrap"
import {Link} from 'gatsby'

const Maintain = () => {
  return (
    <div className="one-stop-website">
      <span className="one-stop-website-temp1 one-stop-foo"></span>
      <span className="one-stop-website-temp2 one-stop-foo"></span>
      <span className="one-stop-website-temp3 one-stop-foo"></span>
      <span className="one-stop-website-temp4 one-stop-foo"></span>
      <div className="title one-stop-foo">You focus on your business, Shoprises will take care of the rest.</div>
      <ul className="one-stop-website-box">
        <li>
          <svg className="icon one-stop-foo">
            <use href="#iconSEOoptimizationonsite" />
          </svg>
          <div className="li-title one-stop-foo">Seo Optimized Website</div>
          <div className="li-text one-stop-foo">We make setting up seo easy.</div>
        </li>
        <li>
          <svg className="icon one-stop-foo">
            <use href="#iconPagebeautification" />
          </svg>
          <div className="li-title one-stop-foo">Design for better result</div>
          <div className="li-text one-stop-foo">Data based Website UI/THEME Design to drive more sales.</div>
        </li>
        <li>
          <svg className="icon one-stop-foo">
            <use href="#iconProductuploadupdate" />
          </svg>
          <div className="li-title one-stop-foo">Efficiency & inspired Product Tool</div>
          <div className="li-text one-stop-foo">Shoprises offers tools to show trending products to inspire you. We also offer efficiency tools to help you import & export your product details within multiple platforms</div>
        </li>
        <li>
          <svg className="icon one-stop-foo">
            <use href="#iconEventoperationsettings" />
          </svg>
          <div className="li-title one-stop-foo">Event & Activity Asistant</div>
          <div className="li-text one-stop-foo">Our dedicated team will offer you multiple professional marketing activities temperate and advice to connect your brand with your audience better.</div>
        </li>
      </ul>
      <a href="mailto:support@shoprises.com"><Button className="one-stop-foo">Contact us</Button></a>
    </div>
  )
}
  
export default Maintain

