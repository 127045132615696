import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LearnMore from "../components/learnmore"
import Maintain from "../components/onestop/maintain"
import Independent from "../components/onestop/inde"
import AdvContent from "../components/onestop/advContent"
import Collect from "../components/onestop/collect"
import ScrollReveal from 'scrollreveal'

const OneStop = () => {
  React.useEffect(()=> {
    ScrollReveal().reveal('.one-stop-foo',
      {
        delay: 0,
        distance: '50px',
        easing: 'ease-in-out',
        origin: "bottom",
        mobile: false,
        duration: 400,
        interval: 50,
        opacity : 0, 
        reset: false,
        init: false
      }
    )
  })
  return (
    <Layout pageInfo={{ pageName: "oneStop" }}>
      <SEO title="ALL-IN-ONE Solution" description="A walkthrough of Shoprises ALL-IN-ONE Solution" path="oneStop"/>
      {/* 大图 */}
      <div 
        className="one-stop-bg"
      >
        <span className="one-stop-bg-1 one-stop-foo"></span>
        <span className="one-stop-bg-2 one-stop-foo"></span>
        <span className="one-stop-bg-3 one-stop-foo"></span>
        <span className="one-stop-bg-4 one-stop-foo"></span>
        <span className="one-stop-bg-5 one-stop-foo"></span>
        <div className="one-stop-bg-text">
          <div className="title one-stop-foo">A walkthrough of Shoprises ALL-IN-ONE Solution</div>
          <div className="text one-stop-foo">Build & Control your own website with less efforts. </div>
        </div>
      </div>

      {/* 帮你做独立站 */}
      <Independent />

      {/* 帮你做广告 */}
      <div className="adv-bg-1">
        <div className="adv-bg-in  one-stop-foo">
          <div className="adv-top">
            <div className="title  one-stop-foo">You focus on your business, Shoprises will take care of the rest</div>
            <div className="text  one-stop-foo">Let Shoprises track and analyze your website traffic from different marketing channels. so you can have a clear understanding of your potential customers. </div>
            <a href="mailto:support@shoprises.com">Contact us</a>
          </div>
          <ul className="adv-bottom">
            <li className="one-stop-foo">
              <svg className="icon">
                <use href="#icontiktok"/>
              </svg>
              <div className="title-item">Tik Tok</div>
            </li>
            <li className="one-stop-foo">
              <svg className="icon">
                <use href="#iconFacebook2"/>
              </svg>
              <div className="title-item">Facebook</div>
            </li>
            <li className="one-stop-foo">
              <svg className="icon">
                <use href="#icontwitterhover_pc1"/>
              </svg>
              <div className="title-item">Twitter</div>
            </li>
            <li className="one-stop-foo">
              <svg className="icon">
                <use href="#iconins"/>
              </svg>
              <div className="title-item">ins</div>
            </li>
            <br />
            
          </ul>
          <a href="mailto:support@shoprises.com">Contact us</a>
        </div>
        
      </div>
      {/* 废弃 */}
      {/* <AdvContent /> */}

      {/* 帮你维护网站 */}
      <Maintain />

      {/* 让ToroCom帮你解决收款渠道 */}
      <Collect />
      
      {/* 二级页脚 */}
      {/* <LearnMore /> */}
    </Layout>
  )
}
  
export default OneStop

