import React from 'react'
import {Container} from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"
const Collect = () => {
  const data = useStaticQuery(graphql`
  query {
    plan6_2:file(relativePath: { eq: "oneStop/plan6_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    plan6_3:file(relativePath: { eq: "oneStop/plan6_3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    plan6_4:file(relativePath: { eq: "oneStop/plan6_4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    plan6_5:file(relativePath: { eq: "oneStop/plan6_5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    plan6_6:file(relativePath: { eq: "oneStop/plan6_6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    main:file(relativePath: { eq: "oneStop/main.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
return (
  <Container className='collect'>
    <div className='collect-title one-stop-foo'>We support many payment methods</div>
    <div className='collect-text one-stop-foo'>More payment methods choice leads to better sales of your website.</div>
    <div className='collect-imgs'>
      <span className='collect-img one-stop-foo'>
        <img 
          src={data.plan6_2.childImageSharp.fluid.src}
          alt=''
        />
        VISA
      </span>
      <span className='collect-img one-stop-foo'>
        <img 
          src={data.plan6_3.childImageSharp.fluid.src}
          alt=''
        />
        Mastercard
      </span>
      <span className='collect-img one-stop-foo'>
        <img 
          src={data.plan6_4.childImageSharp.fluid.src}
          alt=''
        />
        Maestro
      </span>
      <span className='collect-img one-stop-foo'>
        <img 
          src={data.plan6_5.childImageSharp.fluid.src}
          alt=''
        />
        Cirrus
      </span>
      <span className='collect-img one-stop-foo'>
        <img 
          src={data.plan6_6.childImageSharp.fluid.src}
          alt=''
        />
        JCB
      </span>
    </div>
  </Container>
  )
}

export default Collect